<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="姓名:">
            <el-input v-model="selectForm.name"></el-input>
          </el-form-item>
          <el-form-item label="缴费单位:">
            <el-select v-model="selectForm.PaymentAccount" placeholder="请选择缴费单位" style="width: 100%">
              <el-option label="格瑞德集团" value="格瑞德集团"></el-option>
              <el-option label="冷却塔公司" value="冷却塔公司"></el-option>
              <el-option label="淄博炭净环保有限公司" value="淄博炭净环保有限公司"></el-option>
              <el-option label="格瑞德环保科技有限公司" value="格瑞德环保科技有限公司"></el-option>
              <el-option label="山东格瑞德人工环境产业设计研究院有限公司" value="山东格瑞德人工环境产业设计研究院有限公司"></el-option>
              <el-option label="山东格物智联信息技术有限公司" value="山东格物智联信息技术有限公司"></el-option>
              <el-option label="格瑞德环境科技有限公司" value="格瑞德环境科技有限公司"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="增员日期:">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="selectForm.dateStart" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="selectForm.dateEnd" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
            </el-col>
          </el-form-item>
          <!-- 这个就是当前页面内，所有的btn列表 -->
          <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        @row-dblclick="handleEdit"
        class="new_table"
      >
        <el-table-column prop="UserName" label="名称"> </el-table-column>
          <el-table-column prop="IDCard" label="身份证"> </el-table-column>
        <el-table-column label="缴费单位" prop="PaymentAccount"></el-table-column>
        <el-table-column label="缴费基数" prop="BaseSalary"></el-table-column>
        <el-table-column label="增员日期" prop="AddDate" :formatter="formatStartTime"></el-table-column>
        <el-table-column label="备注" show-overflow-tooltip prop="Remark"></el-table-column>
        <!-- <el-table-column label="备注" prop="Remark"></el-table-column> -->
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '公积金增员' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
        <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="增员人:" prop="UserName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-input type="text" :disabled="true" v-model="SakaryForm.UserName">
                            <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="增员时间:"  prop="AddDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                        <el-date-picker type="date" v-model="SakaryForm.AddDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="缴费单位:" prop="PaymentAccount">
                        <el-select v-model="SakaryForm.PaymentAccount" placeholder="缴费单位" style="width: 100%">
                            <el-option label="格瑞德集团" value="格瑞德集团"></el-option>
                            <el-option label="冷却塔公司" value="冷却塔公司"></el-option>
                            <el-option label="淄博炭净环保有限公司" value="淄博炭净环保有限公司"></el-option>
                            <el-option label="格瑞德环保科技有限公司" value="格瑞德环保科技有限公司"></el-option>
                            <el-option label="山东格瑞德人工环境产业设计研究院有限公司" value="山东格瑞德人工环境产业设计研究院有限公司"></el-option>
                            <el-option label="山东格物智联信息技术有限公司" value="山东格物智联信息技术有限公司"></el-option>
                            <el-option label="格瑞德环境科技有限公司" value="格瑞德环境科技有限公司"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="缴费级别:" prop="houseFundDicName" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                        <el-select v-model="SakaryForm.houseFundDicName"
                                   placeholder="缴费级别"
                                   @change="CertTypeModel($event)"
                                   style="width: 100%">
                            <el-option v-for="item in CertTypeLists"
                                       :key="item.Id"
                                       :label="item.Name"
                                       :value="item.Name"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>

                <el-col :span="12">
                    <el-form-item label="缴费基数:" prop="BaseSalary">
                        <el-input type="text" v-model="SakaryForm.BaseSalary" disabled></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="公司缴费(实缴):" prop="FactorySalary">
                        <el-input type="text" v-model="SakaryForm.FactorySalary" disabled>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="公司缴费(应缴):" prop="FactorySalary02">
                        <el-input type="text" v-model="SakaryForm.FactorySalary02" disabled> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="个人缴费(实缴):" prop="UserSalary">
                        <el-input type="text" v-model="SakaryForm.UserSalary" disabled> </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="个人缴费(应缴):" prop="UserSalary02">
                        <el-input type="text" v-model="SakaryForm.UserSalary02" disabled> </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="是否能提取:" prop="isExtract">
                        <el-switch v-model="SakaryForm.isExtract" active-color="#67c23a" inactive-color="#e6a23c" active-text="是" inactive-text="否" disabled> </el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注：" prop="Remark">
                  <el-input type="text" v-model="SakaryForm.Remark"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="text-align: center">

                    <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
    <el-dialog
      title="公积金减员"
      :visible.sync="InsuranVisible"
      v-model="InsuranVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="InsuranForm"
        class="demo-form-inline"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="减员时间:"
              prop="ReduceDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="InsuranForm.ReduceDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="缴费基数:" prop="BaseSalary">
              <el-input type="text" v-model="InsuranForm.BaseSalary">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="减员原因:" prop="ReduceReason">
              <el-input
                type="textarea"
                v-model="InsuranForm.ReduceReason"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" style="text-align: center">
         
              <el-button
                type="primary"
                v-on:click="onInsuranFormSubmit('InsuranForm')"
                >提交</el-button
              >
        
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import {
  GetHouseFundsPersonList,
  AddHouseFundsPerson,
  AddHouseFundsPersonReduceInfo,
        UpdateHouseFundsPerson,
        GetDicHouseFundsPageList
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose },
  data() {
    return {
      searchVal: "",
      selectForm: {
        name: "",
        PaymentAccount:"",
        // dateStart:new Date().getFullYear()-1+"-01-01" ,
        dateStart:null,
        dateEnd:new Date(),
        // dateStart:null,
        // dateEnd:null,
      },
      loading: true,
      choosedusers: [],
      CertTypeLists: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      InsuranVisible: false,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      addUserVisible: false,
      SakaryForm: {
        Remark:"",
        UserId: "",
        UserName: "",
        WorkDate: "",
        AddDate: "",
        AddReason: "",
          houseFundDicId: "",
          houseFundDicName: "",
        Name: "",
        BaseSalary: "",
        Factory1: "",
        Factory2: "",
        Factory3: "",
        Factory4: "",
        Factory5: "",
        User1: "",
        User2: "",
        User3: "",
        User4: "",
        User5: "",
      },
      InsuranForm: {
        UserId: "",
        ReduceDate: null,
        ReduceReason: "",
        BaseSalary: "",
      },
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      console.log(newdata);

      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      this.addUserVisible = false;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.name,
        paymentAccount:this.selectForm.PaymentAccount,
        timeStart:this.selectForm.dateStart,
        timeEnd:this.selectForm.dateEnd,
      };
      console.log(para);
      GetHouseFundsPersonList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
      handleEdit() {
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要编辑的一行数据！",
                  type: "error",
              });
              return;
          }
          GetDicHouseFundsPageList().then((res) => {
              this.CertTypeLists = res.data.response.data;
              this.CertTypeModel(row.houseFundDicName);
          });
          console.log(row)
          this.SakaryForm = Object.assign({}, row);
          (this.operation = false), (this.SakaryVisible = true);
      },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }

      this.InsuranForm.UserId = row.UserId;
      this.InsuranForm.BaseSalary = row.BaseSalary;
      this.InsuranVisible = true;
    },
      handleAdd() {
          GetDicHouseFundsPageList().then((res) => {
              this.CertTypeLists = res.data.response.data;
              this.CertTypeModel(row.houseFundDicName);
          });
      (this.SakaryForm = {
        Remark:"",
        UserId: "",
        UserName: "",
        WorkDate: "",
        AddDate: "",
        AddReason: "",
          houseFundDicId: "",
          houseFundDicName: "",
        Name: "",
        BaseSalary: "",
        Factory1: "",
        Factory2: "",
        Factory3: "",
        Factory4: "",
        Factory5: "",
        User1: "",
        User2: "",
        User3: "",
        User4: "",
        User5: "",
      }),
        (this.operation = true),
        (this.SakaryVisible = true);
      },
      CertTypeModel(id) {
          let obj = {};
          obj = this.CertTypeLists.find((item) => {
              //model就是上面的数据源
              return item.Name === id; //筛选出匹配数据
          });
          console.log(obj)
          this.SakaryForm.houseFundDicId = obj.Id;
          this.SakaryForm.houseFundDicName = obj.Name;
          this.SakaryForm.BaseSalary = obj.BaseSalary;
          this.SakaryForm.FactorySalary = obj.FactorySalary;
          this.SakaryForm.FactorySalary02 = obj.FactorySalary02;
          this.SakaryForm.UserSalary = obj.UserSalary;
          this.SakaryForm.UserSalary02 = obj.UserSalary02;
          this.SakaryForm.isExtract = obj.isExtract;
          // this.SakaryForm.Remark = obj.Remark;
      },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          console.log(para);
          if (this.operation) {
            //新增
            AddHouseFundsPerson(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData();
              } else {
                this.$message({
                  message: "添加失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            //修改
            UpdateHouseFundsPerson(para).then((res) => {
              console.log(res);
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          }
        });
      });
    },
    onInsuranFormSubmit(formName) {
      let para = Object.assign({}, this.InsuranForm);
      AddHouseFundsPersonReduceInfo(para).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.$message({
            message: "减员成功",
            type: "success",
            duration: 5000,
          });
          this.InsuranVisible = false;
          this.getData(false);
        } else {
          this.$message({
            message: "减员失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

